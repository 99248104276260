import React, { lazy, Suspense, useEffect, useState } from 'react'
import './bootstrap/css/bootstrap.min.css';

import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4';
import { Row, Col } from "react-bootstrap";
import './App.css';
import { getMaintenance } from './tools/constants';
import Loading from './components/general-components/Loading';

import Maintenance from './components/ui/error/Maintenance';



const Login = lazy(() => import("./components/ui/login/Login"));
const GoogleAuth = lazy(() => import("./components/ui/login/GoogleAuth"));
const Index = lazy(() => import("./components/ui/Index"));
const Home = lazy(() => import("./components/ui/home/Home"));
const CalendarSite = lazy(() => import("./components/ui/calendar/CalendarSite"));
const Schedules = lazy(() => import("./components/ui/schedules/Schedule-planitesting"));
const Assistance = lazy(() => import("./components/ui/assistance/Assistance"));
const AssistanceClass = lazy(() => import("./components/ui/assistance/AssistanceClass"));
const AssistanceStudent = lazy(() => import("./components/ui/assistance/AssistanceStudent"));
const Grades = lazy(() => import("./components/ui/grades/Grades"));
const Report = lazy(() => import("./components/ui/grades/Report"));
const EvaluativeReport = lazy(() => import("./components/ui/grades/EvaluativeReport"));
const ReportCardPrimaryLevel = lazy(() => import("./components/ui/reports/ReportCardPrimaryLevel"));
const EvaluativeReportSecondaryLevel = lazy(() => import("./components/ui/reports/EvaluativeReportSecondaryLevel"));
const EvaluativeReportPrimaryLevel = lazy(() => import("./components/ui/reports/EvaluativeReportPrimaryLevel"));
const ObservationsPrimaryLevel = lazy(() => import("./components/ui/reports/CommentsPrimaryLevel"));
const Settings = lazy(() => import("./components/ui/settings/Settings"));
const SchoolYear = lazy(() => import("./components/ui/settings/SchoolYear"));
const Frames = lazy(() => import("./components/ui/settings/Periods"));
const Sanctions = lazy(() => import("./components/ui/sanctions/Sanctions"));
const CoursesSite = lazy(() => import("./components/ui/courses/Courses"));
const StudentSite = lazy(() => import("./components/ui/courses/StudentSite"));
const CoursesConfig = lazy(() => import("./components/ui/settings/Courses"));
const TestConfig = lazy(() => import("./components/ui/settings/Tests"));
const CurriculumConfig = lazy(() => import("./components/ui/settings/Curriculum"));
const StudentsConfig = lazy(() => import("./components/ui/settings/Students"));
const HolidaysConfig = lazy(() => import("./components/ui/settings/Holidays"));
const Classes = lazy(() => import("./components/ui/settings/Classes"));
const Class = lazy(() => import("./components/ui/settings/Class"));
const CurriculumYears = lazy(() => import("./components/ui/settings/CurriculumYears"));
const CurriculumSubjects = lazy(() => import("./components/ui/settings/CurriculumSubjects"));
const GradesConfig = lazy(() => import("./components/ui/settings/Grades"));
const Terms = lazy(() => import("./components/ui/terms-conditions/Terms"));
const Privacy = lazy(() => import("./components/ui/terms-conditions/Privacy"));
const DefaultNodals = lazy(() => import("./components/ui/settings/DefaultNodals"));
const Users = lazy(() => import("./components/ui/users/Users"));
const UserDetail = lazy(() => import("./components/ui/users/UserDetail"));
const Students = lazy(() => import("./components/ui/students/Students"));
const Teachers = lazy(() => import("./components/ui/teachers/Teachers"));
const ScheduleConfig = lazy(() => import("./components/ui/settings/Schedule"));
const DataEntryStudentFile = lazy(() => import("./components/ui/courses/DataEntryStudentFile"));
const Registration = lazy(() => import("./components/ui/registration/Registration"));
const RegistrationDetail = lazy(() => import("./components/ui/registration/RegistrationDetail"));
const NotEnabled = lazy(() => import("./components/ui/error/NotEnabled"));
const ChooseRole = lazy(() => import("./components/ui/users/ChooseRole"));
const Signatures = lazy(() => import("./components/ui/settings/Signatures"));
const Reports = lazy(() => import("./components/ui/reports/Reports"));
const Communications = lazy(() => import("./components/ui/communications/Communications"));
const Indicators = lazy(() => import("./components/ui/indicators/Indicators"));
const ExamTables = lazy(() => import("./components/ui/examTables/ExamTables"));
const PendingSubjects = lazy(() => import("./components/ui/examTables/PendingSubjects"));
const Chat = lazy(() => import("./components/ui/communications/Chat"));
const CommGroups = lazy(() => import("./components/ui/settings/CommGroups"));
const ChatGroups = lazy(() => import("./components/ui/settings/ChatGroups"));
const DataTypesStudents = lazy(() => import("./components/ui/settings/DataTypesStudents"));
const Help = lazy(() => import("./components/ui/help/Help"));
const SanctionsSettings = lazy(() => import("./components/ui/settings/Sanctions"));
const SanctionsByStudents = lazy(() => import("./components/ui/sanctions/SanctionsByStudent"));
const GradesByStudent = lazy(() => import("./components/ui/grades/GradesByStudents"));
const ChooseChild = lazy(() => import("./components/ui/users/ChooseChild"));
const EvaluativeReportSecondaryLevelByStudents = lazy(() => import("./components/ui/reports/EvaluativeReportSecondaryLevelByStudents"));
const CABA_SVP_SECU_Regulation = lazy(() => import("./components/ui/regulations/CABA-SVP-SECU-Regulation"));
const TeachersAssistance = lazy(() => import("./components/ui/teachers/TeachersAssistance"));
const TeacherSite = lazy(() => import("./components/ui/teachers/TeacherSite"));
const PsychopedagogicTags = lazy(() => import("./components/ui/settings/PsychopedagogicTags"));
const InscriptionNextYear = lazy(() => import("./components/ui/login/InscriptionNextYear"));
const Debug = lazy(() => import("./components/ui/login/Debug"));
const NavBarConfig = lazy(() => import("./components/ui/settings/NavBar"));
const IndicatorsGeneralPanel = lazy(() => import("./components/ui/indicators/IndicatorsGeneralPanel"));
const CommentsBySubject = lazy(() => import("./components/ui/reports/CommentsBySubject"));
const Qualifier = lazy(() => import("./components/ui/grades/Qualifier"))
const PickUps = lazy(() => import("./components/ui/students/PickUps"));
const ClassesList = lazy(() => import("./components/ui/classes/ClassesList"));
const ClassDetail = lazy(() => import("./components/ui/classes/ClassDetail"));
const TeachersSchedule = lazy(() => import('./components/ui/teachers/TeachersSchedule'));
const CreateScheduleDnd = lazy(() => import('./components/ui/settings/CreateScheduleDnd'));
const TeachersAvailability = lazy(() => import('./components/ui/settings/TeachersAvailability'));

ReactGA.initialize('G-5PBED81TPP');

ReactGA.send({
	hitType: "pageview",
	page: window.location.pathname
});

export function App() {
	const [isMaintenance, setIsMaintenance] = useState(null);

	useEffect(() => {
		getMaintenance().then((maintenance) => {
			setIsMaintenance(maintenance);
		});
	}, []);

	const ProcessRoutes = () => {
		//let r = getUserRole();
		return (
			<Suspense fallback={
					<div style={{
						
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						width: "100dvw",
						height: "100dvh", backgroundColor: '#F2F3F9'
					}}>
						<Loading animation={'border'} classExtra='loading-100' />
					</div>}>
				<Routes>
					{isMaintenance ?
						<>
							<Route path="/mantenimiento" element={<Maintenance />} />
							<Route path="*" element={<Navigate to="/mantenimiento" replace />} />
						</>
						:
						<>

							{/* <Route path="/test-valen" element={<Testing />} />*/}
							<Route path="/login" element={<Login />} />
							<Route path="/login/googleauth" element={<GoogleAuth />} />
							<Route path="/" element={<Index />} />
							<Route path="/inicio" element={<Home />} />
							<Route path="/calendario" element={<CalendarSite />} />
							<Route path="/horarios" element={<Schedules />} />
							<Route path="/asistencia-curso" element={<Assistance />} />
							<Route path="/asistencia-materia" element={<AssistanceClass />} />
							<Route path="/estudiante/asistencia" element={<AssistanceStudent />} />
							<Route path="/calificaciones" element={<Grades />} />
							<Route path="/informe-valorativo" element={<EvaluativeReport />} />
							<Route path="/reportes/boletin/vista-previa" element={<ReportCardPrimaryLevel />} />
							<Route path="/reportes/secundaria/informe-valorativo" element={<EvaluativeReportSecondaryLevel />} />
							<Route path="/reportes/calificaciones-primaria" element={<EvaluativeReportPrimaryLevel />} />
							<Route path="/reportes/observaciones-primaria" element={<ObservationsPrimaryLevel />} />
							<Route path="/reportes/semblanzas-inicial" element={<CommentsBySubject />} />
							<Route path="/configuracion" element={<Settings />} />
							<Route path="/configuracion/ciclos-lectivos" element={<SchoolYear />} />
							<Route path="/configuracion/periodos" element={<Frames />} />
							<Route path="/sanciones" element={<Sanctions />} />
							<Route path="/cursos" element={<CoursesSite />} />
							<Route path="/estudiante" element={<StudentSite />} />
							<Route path="/configuracion/cursos" element={<CoursesConfig />} />
							<Route path="/configuracion/evaluaciones" element={<TestConfig />} />
							<Route path="/configuracion/planes-estudio" element={<CurriculumConfig />} />
							<Route path="/configuracion/cursos/alumnos" element={<StudentsConfig />} />
							<Route path="/configuracion/cursos/materias" element={<Classes />} />
							<Route path="/configuracion/cursos/materia" element={<Class />} />
							<Route path="/configuracion/planes-estudio/años" element={<CurriculumYears />} />
							<Route path="/configuracion/planes-estudio/años/materias" element={<CurriculumSubjects />} />
							<Route path="/configuracion/calificaciones" element={<GradesConfig />} />
							<Route path="/configuracion/horarios" element={<ScheduleConfig />} />
							<Route path="/configuracion/feriados" element={<HolidaysConfig />} />
							<Route path="/terminos" element={<Terms />} />
							<Route path="/privacidad" element={<Privacy />} />
							<Route path="/configuracion/nodales-predeterminados" element={<DefaultNodals />} />
							<Route path="/usuarios" element={<Users />} />
							<Route path="/usuarios/detalle" element={<UserDetail />} />
							<Route path="/alumnos" element={<Students />} />
							<Route path="/dataentry" element={<DataEntryStudentFile />} />
							<Route path="/inscripciones" element={<Registration />} />
							<Route path="/inscripciones/detalle" element={<RegistrationDetail />} />
							<Route path="/profesores" element={<Teachers />} />
							<Route path="/no-habilitado" element={<NotEnabled />} />
							<Route path="/seleccionar-rol" element={<ChooseRole />} />
							<Route path="/configuracion/firmas-docentes" element={<Signatures />} />
							<Route path="/reportes/boletin" element={<Reports />} />
							<Route path="/comunicaciones" element={<Communications />} />
							<Route path="/indicadores" element={<Indicators />} />
							<Route path="/mesas-examen" element={<ExamTables />} />
							<Route path="/materias-adeudadas" element={<PendingSubjects />} />
							<Route path="/configuracion/comunicados" element={<CommGroups />} />
							<Route path="/configuracion/gruposChat" element={<ChatGroups />} />
							<Route path="/configuracion/tiposDatos" element={<DataTypesStudents />} />
							<Route path="/configuracion/sanciones" element={<SanctionsSettings />} />
							<Route path="/ayuda" element={<Help />} />
							<Route path="/estudiante/resumen/" element={<StudentSite />} />
							<Route path="/estudiante/sanciones" element={<SanctionsByStudents />} />
							<Route path="/estudiante/calificaciones" element={<GradesByStudent />} />
							<Route path="/seleccionar-estudiante" element={<ChooseChild />} />
							<Route path="/estudiante/informe-valorativo" element={<EvaluativeReportSecondaryLevelByStudents />} />
							<Route path="/estudiante/boletin" element={<EvaluativeReportSecondaryLevelByStudents />} />
							<Route path="/secundaria/reglamento" element={<CABA_SVP_SECU_Regulation />} />
							<Route path="/profesores/asistencia" element={<TeachersAssistance />} />
							<Route path="/profesor" element={<TeacherSite />} />
							<Route path="/configuracion/informe-psicopedagogico" element={<PsychopedagogicTags />} />
							<Route path="/matriculacion" element={<InscriptionNextYear />} />
							<Route path="/debug" element={<Debug />} />
							<Route path="/configuracion/navbar" element={<NavBarConfig />} />
							<Route path="/indicadores-red" element={<IndicatorsGeneralPanel />} />
							<Route path="/calificadores" element={<Qualifier />} />
							<Route path="/retiros" element={<PickUps />} />
              				<Route path="/clases" element={<ClassesList/>} />
						  	<Route path="/clase" element={<ClassDetail/>} />
						  	<Route path="/mis-horarios" element={<TeachersSchedule/>}/>
						  	<Route path="/configuracion/disponibilidad-horaria" element={<TeachersAvailability/>}/>
							<Route path="/disponibilidad-horaria" element={<TeachersAvailability/>}/>
						  	<Route path="/planificacion" element={<CreateScheduleDnd/>}/>
							<Route path="*" element={<Navigate to="/" replace />} />
						</>
					}
				</Routes>
			</Suspense>
		)
	}

	return (
		<div>
			<Router>
				<Row className="m-0">
					{isMaintenance == null ?
						<div className='justify-content-center'>
							<Loading animation={'border'} classExtra='loading-100' />
						</div>
						:
						isMaintenance != true
							? <ProcessRoutes />
							: <Routes>
								<Route path="/mantenimiento" element={<Maintenance />} />
								<Route path="/*" element={<Navigate to="/mantenimiento" replace />} />
							</Routes>
					}
				</Row>
			</Router>
		</div>
	);
}